<template>
<el-row>
    
  <div class="maxBox">
    <el-col :span="18">
                <div class="recom-help">需要获得帮助？</div>
                <div class="searchBox">
                    <input placeholder="详细描述下您的问题？"  class="inputBox"  v-model="inputContent" @keyup.enter="getResult"/>
                    <i class="el-icon-search" @click="getResult"></i>
                </div>
    </el-col>
                <el-col :span="18">
                <div class="resultBox">
                    <el-empty description="开始搜索吧" v-show="beforeClick"></el-empty>
                        <div class="loading-out" v-show="inClick">
                            
                            <div class="loading">
                            <div class="shape shape-1"></div>
                            <div class="shape shape-2"></div>
                            <div class="shape shape-3"></div>
                            <div class="shape shape-4"></div>
                            </div>
                        </div>
                    <div v-show="afterClick">
                        <div class="result-1">
                        <el-card shadow="always" class="answer-qa">
                        <div class="content">
                        <img :src="require('@/assets/answer.png')" class="icon-reco"/>
                    
                        <div>
                         {{suggestion_answer}}
                        <el-rate
                            v-model="value1"
                            :texts = texts
                            show-text
                            class="rate-ele"
                            :colors="colors">
                        </el-rate>
                        </div>
                        </div>
                        </el-card>
                        
                        <div class="related-content">
                            <div class="related-conten-help">推荐搜索</div>
                            <div v-for="item in score_answer.slice(0,6)" :key="item._id">
                                <div class="related-content-text">
                                <span>
                                    {{item._source.paragraph}}
                                </span>
                                <!-- <el-rate
                                    v-model="r_value"
                                    :texts = texts
                                    class="rate-ele"
                                    show-text>
                                </el-rate> -->
                                <el-divider></el-divider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </el-col>
            <el-col :span="6">
                <el-row class="link-aws">
                 
                <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true" >
                    <el-tab-pane label="推荐阅读" name="first">
                        <el-card shadow="always">
                            <el-card :body-style="{ padding: '0px' }">
                                <el-link  target="_blank" :underline="false" href="https://dev.amazoncloud.cn/video/videoDetail?id=6318535dbba1616cb548fc35&sortIndex=0&sortId=undefined&tagIndex=0&tagId=undefined">
                                <img :src="require('@/assets/Serverless.png')" class="image">
                                <div style="padding: 14px;">
                                    <span>云架构师需要了解无服务器</span>
                                
                                </div>
                            </el-link>
                                </el-card>
                                <el-divider></el-divider>
                                <el-card :body-style="{ padding: '0px' }">
                                    <el-link  target="_blank" :underline="false" href="https://dev.amazoncloud.cn/video/videoDetail?id=646ddca599ab8c6709d15305">
                                <img :src="require('@/assets/dynamodb.jpg')" class="image">
                                <div style="padding: 14px;">
                                    <span>Amazon DynamoDB </span>
                                </div>
                            </el-link>
                                </el-card>
                                <el-divider></el-divider>
                                <el-card :body-style="{ padding: '0px' }">
                                    <el-link  target="_blank" :underline="false" href="https://dev.amazoncloud.cn/video/videoDetail?id=6445fe1f13eafe780ecafab4&sortIndex=undefined&sortId=undefined&tagIndex=undefined&tagId=undefined">
                                <img :src="require('@/assets/code.png')" class="image">
                                <div style="padding: 14px;">
                                    <span>Amazon CodeWhisperer </span>
                    
                                </div>
                            </el-link>
                                </el-card>
                                <el-divider></el-divider>
                                <el-card :body-style="{ padding: '0px' }">
                                    <el-link  target="_blank" :underline="false" href="https://dev.amazoncloud.cn/video/videoList?catagory&col=63ff06b44891d26f36585a90&sortIndex=0&sortId&tagIndex=0&tagId">
                                <img :src="require('@/assets/loghub.png')" class="image">
                                <div style="padding: 14px;">
                                    <span>日志通 Log Hub </span>
                    
                                </div>
                            </el-link>
                                </el-card>
                                <el-divider></el-divider>
                        </el-card>

                    </el-tab-pane>
                    <el-tab-pane label="常用链接" name="second">
                        <el-card shadow="always">
                        <el-link  target="_blank" href="https://dev.amazoncloud.cn/interlocution">开发者社区-问答专栏</el-link>
                        <el-divider></el-divider>
                        <el-link  target="_blank" href="https://dev.amazoncloud.cn/column/columnDetail?id=63fd9b38c31bed69a2105fbb">开发者社区-解决方案专栏</el-link>
                        <el-divider></el-divider>
                        <el-link  target="_blank" href="https://dev.amazoncloud.cn/column/columnDetail?id=640ff5713d950b57b3f9f352">开发者社区-云迁移专栏</el-link>
                        <el-divider></el-divider>
                        <el-link  target="_blank" href="https://dev.amazoncloud.cn/activity" >开发者社区-活动</el-link>
                        <el-divider></el-divider>
                        <el-link  target="_blank" href="https://dev.amazoncloud.cn/competition">开发者社区-赛事</el-link>
                    </el-card>
                    </el-tab-pane>
                    <el-tab-pane label="社区提问" name="third">
                        <el-card :body-style="{ padding: '20px 10%' }">
                            <div class="third-label">
                                
                            <img :src="require('@/assets/zhihu.png')" />
                            <span style="font-size: 16px;">亚马逊云科技讨论专区</span>
                            <el-link  target="_blank" :underline="false" href="https://www.zhihu.com/topic/19558548/top-answers">
                                <el-button type="warning" plain>前往<i class="el-icon-share el-icon--right"></i></el-button>
                            </el-link>
                            </div>
                        <el-divider></el-divider>
                        <div class="third-label">
                      
                      <img :src="require('@/assets/seg.png')" />
                      <span style="font-size: 16px;">亚马逊云科技讨论专区</span>
                      <el-link  target="_blank" :underline="false" href="https://segmentfault.com/t/amazon-web-services/questions">
                        <el-button type="warning" plain>前往<i class="el-icon-share el-icon--right"></i></el-button>
                      </el-link>
                      </div>
                      <el-divider></el-divider>
                      <div class="third-label">
                      
                      <img :src="require('@/assets/repost.png')" />
                      <span style="font-size: 16px;">re:Post 专区</span>
                      <el-link  target="_blank" :underline="false" href="https://repost.aws/zh-Hans?trk=859f3c3f-c0fa-4b16-9fd5-8f05cf9273de">
                      <el-button type="warning" plain>前往<i class="el-icon-share el-icon--right"></i></el-button>
                      </el-link>
                      </div>
                      <el-divider></el-divider>
                      <div class="third-label">
                      
                      <img :src="require('@/assets/csdn.png')" />
                      <span style="font-size: 16px;">亚马逊云科技讨论专区</span>
                      <el-link  target="_blank" :underline="false" href="https://bbs.csdn.net/forums/awsdev">
                      <el-button type="warning" plain>前往<i class="el-icon-share el-icon--right"></i></el-button>
                      </el-link>
                      </div>
                    </el-card>
                    </el-tab-pane>
                </el-tabs>

                </el-row>
            </el-col>
            </div>
           
      
       
        </el-row>
</template>

<script>
import axios from 'axios'
export default {
  name: 'SearchBox',
  props: {
    msg: String
  },
  
  data() {
    return {
      currentTime: "",
      currentDate: "",
      activeName: 'first',
      value1: null,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      r_value: null,
      texts: ['没用','一般','有用','满意','惊喜'],
      beforeClick: true,
      afterClick: false,
      inClick: false,
      inputContent: "",
      suggestion_answer: "",
      score_answer: []
    };
 },
 methods: {
  handleClick(tab, event) {
    console.log(tab, event);
  },
  getResult() {
    if (this.inputContent.length < 6 || this.inputContent.trim().length === 0) {
        this.$message({
          message: '您的描述太短了，请您填写更多信息~',
          type: 'warning'
        });
    }else{
        this.afterClick = false
        this.beforeClick = false
        this.inClick = true
        axios.get('https://dg89ux1te6.execute-api.us-east-1.amazonaws.com/prod/search_knn_doc?q='+this.inputContent+'&ind=awsfaq&knn=1')
        .then(response => {
            console.log(response)
            this.inClick = false
            this.afterClick = true
            this.suggestion_answer = response.data.suggestion_answer
            this.score_answer = response.data.body.hits.hits

        })
        .catch(error => {
        console.log(error)
        this.$message({
          message: '服务器错误，请您刷新后尝试~',
          type: 'warning'
        });
        })
    }
    }
   
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.result-1{
 padding-top: 20px;
}
.third-label{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.image {
    width: 100%;
    height: 100%;
  }
.image-third{
    width: 100%;
    height: 100%;

}
li{
    list-style: none;
}
.maxBox{
    display: inline-block;
    width: 100%;
}
.recom-help{
    margin-left: 10%;
    text-align: left;
    font-size: 48px;
    color: black;
}

.searchBox{
    margin-left: 10%;
    border-bottom:2px solid #7a7676 ;
    margin-right: 20%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}
.el-icon-search{
    line-height: 48px;
    font-size: 34px;
    color: #7a7676;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}
.el-icon-search:hover {
    transform: scale(0.8);
}
.inputBox{
    border: none;
    flex: 1;
    text-align: left;
    font-size: 20px;
}
.inputBox:focus-visible {
    outline: none;
}
.resultBox{
    background-color: #f7f7f7;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    
}
.link-aws{
  padding-left: 20px;
  background-color: hsl(0, 0%, 95%);;
  height: 100vh;
  padding-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.answer-qa{
    width: 80%;
    margin-left: 10%;
    font-size: 16px;
    color: #7a7676;
}
.content{
    display: flex;
    margin-top: 10px;
}
.related-content{
    width: 80%;
    margin-left: 10%;
    margin-top: 30px;
    
}
.related-conten-help{
    font-size: 28px;
    margin-bottom: 20px;
    color: #7a7676;
}
.icon-reco{
    min-width: 24px;
    height: 24px;
    color: #7a7676;
    margin-right: 10px;
}
.rate-ele{
    text-align: right;
    margin-top: 10px;
    font-size: 16px;
}
.loading-out{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}
.loading {
  width: 30px;
  height: 30px;
  position: relative;
  transform: rotate(45deg);
  animation: animationContainer 1s ease infinite;
}

.shape {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
}

.shape-1 {
  background-color: #1875e5;
  left: 0;
  animation: animationShape1 0.3s ease infinite alternate;
}

.shape-2 {
  background-color: #c5523f;
  right: 0;
  animation: animationShape2 0.3s ease infinite 0.3s alternate;
}

.shape-3 {
  background-color: #499255;
  bottom: 0;
  animation: animationShape3 0.3s ease infinite 0.3s alternate;
}

.shape-4 {
  background-color: #f2b736;
  right: 0;
  bottom: 0;
  animation: animationShape4 0.3s ease infinite alternate;
}

@keyframes animationContainer {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animationShape1 {
  0% {
    transform: translate(5px, 5px);
  }

  100% {
    transform: translate(-3px, -3px);
  }
}

@keyframes animationShape2 {
  0% {
    transform: translate(-5px, 5px);
  }

  100% {
    transform: translate(3px, -3px);
  }
}

@keyframes animationShape3 {
  0% {
    transform: translate(5px, -5px);
  }

  100% {
    transform: translate(-3px, 3px);
  }
}

@keyframes animationShape4 {
  0% {
    transform: translate(-5px, -5px);
  }

  100% {
    transform: translate(3px, 3px);
  }
}
</style>
