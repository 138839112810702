<template>
  <div>
    <el-row>
      <el-col :span="20"><div class="aws-logo"></div></el-col>
      <el-col :span="4"><div class="contact-us">
        <el-row>
          <a href="https://pages.awscloud.com/communication-preferences?trk=homepage"  target="_blank" class="elink" >
             <el-avatar :src="require('@/assets/gmail.png')" size="small" class="avatar-right" >
            
        </el-avatar> </a>
    
        <el-popover
        placement="top-start"
        width="150"
        trigger="hover">
        <el-image 
        style="width: 150px; height: 150px"
        :src="require('@/assets/DCSub.jpeg')" 
        >
      </el-image>
        <el-avatar  slot="reference" :src="require('@/assets/qr-code.png')" size="small" class="avatar-right">
        </el-avatar> 
      </el-popover>
      </el-row>
    <el-row>
      <div class="time-cal">
        <p>{{ currentTime }}</p>
        <p>{{ currentDate }}</p>
      </div>
    </el-row>
      </div></el-col>
    </el-row>
    <el-row>
      
  
        <search-box/>
     

    </el-row>

  </div>
  
</template>

<script>
import SearchBox from './components/SearchBox.vue'

export default {
  name: 'App',
  components: {
    SearchBox
  },
  data() {
    return {
      currentTime: "",
      currentDate: ""
    };
 },
  mounted() {
    setInterval(() => {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
      this.currentDate = now.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }, 1000);
  }
}

</script>

<style>
.image {
    width: 100%;
    display: block;
  }

.aws-logo {
  margin-top: 20px;
  margin-left: 10%;
  background-image: url('@/assets/logo.png');
  background-size: 60px 60px;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
}
.contact-us{
  margin-top: 20px;
  text-align: right;
  margin-right: 60px
}
.time-cal{
  margin-top: 20px;
  font-size: 16px;
  color: #7a7676 ;

}
.avatar-right{
  margin-left: 20px;
  margin-top: 20px;
 
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
